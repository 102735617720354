<template>
  <!-- 个人中心 - 单个收藏夹 -->
  <div class="collect-detail">
    <div class="folder-title">
      <p><span @click="$router.go(-1)" class="iconfont icon-moreandmore-copy-new-02-copy"></span>{{ name }}</p>
      <div class="caozuo" v-if="selectList.length > 0">
        <p class=""><span class="iconfont " :class="selectList.length == dataList.length ? 'icon-success' : ''"
            @click="selectAll"></span>全选｜已选{{ selectList.length }}项</p>
        <div @click.stop="moveFolder()"><img src="@/assets/image/common/move.png" alt="">移动</div>
        <div @click.stop="delFolder()"><img src="@/assets/image/common/delfff.png" alt="">删除</div>
      </div>
    </div>
    <div class="data-scroll">
      <div class="data-list" id="coryright-image-list" v-if="dataList.length > 0" v-infinite-scroll="load"
        infinite-scroll-disabled="disabled">
        <!-- 图片 -->
        <template v-if="category == 1">
          <div @click="getMsgFormSon(item.editor_id)" class="image-card" v-for="item in dataList" :key="item.id"
            @mouseover="showcheck = item.editor_id" @mouseout="showcheck = ''">
            <a href="javascript:void(0);">
              <img :src="item.small_preview" :width="item.width" :height="item.height" alt="">
            </a>
            <div class="noclick" v-if="item.grounding == 1">图片已下架</div>
            <div class="check-btn" v-if="item.grounding != 1" @click.stop="selectItem(item.editor_id)"
              v-show="selectList.length > 0 || showcheck == item.editor_id"><span
                :class="selectList.indexOf(item.editor_id) >= 0 ? 'icon-success' : ''" class="iconfont "></span></div>
            <div class="image-item-shadowbox">
              <el-tooltip content="移动" placement="top" popper-class="card-tooltip" v-if="item.grounding != 1"
                :style="'right: 50px'">
                <div class="cardiconbox iconbox-collect iconbox-move" @click.stop="moveFolder(item)">
                  <img class="init" src="@/assets/image/common/imgmove.png" />
                </div>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" popper-class="card-tooltip">
                <div class="cardiconbox iconbox-collect" @click.stop="delFolder(item)">
                  <img class="init" src="@/assets/image/common/imgdel.png" />
                </div>
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- 视频 -->
        <template v-else>
          <div @click="getMsgFormSon(item.editor_id)" class="image-card" v-for="item in dataList" :key="item.id"
            @mouseover="showcheck = item.editor_id" @mouseout="showcheck = ''">
            <a href="javascript:void(0);" @mouseenter="productEnter(item)" @mouseleave="productLeave(item)">
              <img :src="item.preview" :id="`img${item.id}`" alt="">
              <div :id="`video-box${item.id}`" class="video-box" v-if="videoPl">
                <video :ref="`video${item.id}`" :id="`video${item.id}`" class="product-video" muted="muted"
                  preload="none">
                  <source :src="item.small_preview" type="video/mp4" />
                </video>
              </div>
            </a>
            <div class="noclick" v-if="item.grounding == 1">视频已下架</div>
            <div class="check-btn" v-if="!(item.grounding == 1)" @click.stop="selectItem(item.editor_id)"
              v-show="selectList.length > 0 || showcheck == item.editor_id"><span
                :class="selectList.indexOf(item.editor_id) >= 0 ? 'icon-success' : ''" class="iconfont "></span></div>
            <div class="image-item-shadowbox">
              <el-tooltip content="移动" placement="top" popper-class="card-tooltip" v-if="!(item.grounding == 1)"
                :style="'right: 50px'">
                <div class="cardiconbox iconbox-collect iconbox-move" @click.stop="moveFolder(item)">
                  <img class="init" src="@/assets/image/common/imgmove.png" />
                </div>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" popper-class="card-tooltip">
                <div class="cardiconbox iconbox-collect" @click.stop="delFolder(item)">
                  <img class="init" src="@/assets/image/common/imgdel.png" />
                </div>
              </el-tooltip>
            </div>
          </div>
        </template>
      </div>
      <div class="loading-icon" v-if="loading">
        <span class="el-icon-loading"></span>
        <span class="images-request-tip">{{ category == 1 ? '图片' : '视频' }}加载中…</span>
      </div>
      <div class="loading-icon" v-if="!loading && dataList.length > 0"><span class="images-request-tip">没有更多{{ category
          == 1 ? '图片' : '视频'
      }}了</span>
      </div>
      <default-page v-if="dataList.length == 0" status="nocollect"></default-page>
    </div>
    <add-collect-dialog v-if="collect.addVisible" :rename="collect.rename" :dirObj="collect.dirObj" :category="category"
      @close="collect.addVisible = false" />
    <collect-dialog v-if="collect.visible" :moveIds="collect.moveIds" :moveFold="true" :oldDirId="dirId"
      :category="category" @close="closeCollect" />
  </div>
</template>
<script>
import AddCollectDialog from "@/components/personal/addCollectDialog";
import CollectDialog from "@/components/collectDialog";
import DefaultPage from "@/components/default";

import { GlobalApi } from "@/config/axios/api";
import $ from "jquery";
import 'justifiedGallery/dist/css/justifiedGallery.css';
import justifiedGallery from 'justifiedGallery';

export default {
  name: "DirectoryDetail",
  data () {
    return {
      dataList: [],
      selectList: [],
      total_count: 0,
      loading: false,
      page: 0,
      page_size: 100,
      dirId: '',
      name: '',
      category: '',  //1图片 2视频
      collect: {
        addVisible: false,
        visible: false,
        rename: false,
        dirObj: {}
      },
      showcheck: '',  //鼠标移入的素材id
      videoPl: false
    }
  },
  components: { AddCollectDialog, CollectDialog, DefaultPage },
  created () {
    this.name = this.$route.query.name;
    this.category = this.$route.query.category;
    this.dirId = parseInt(this.$route.params.id);
    this.initData()
  },
  computed: {
    noMore () {
      return this.total_count <= this.dataList.length
    },
    disabled () {
      return this.loading || this.noMore
    }
  },
  mounted () {
    window.addEventListener('keyup', this.windowKeyUpHandler)
  },
  destroyed () {
    window.removeEventListener('keyup', this.windowKeyUpHandler)
  },
  methods: {
    getMsgFormSon (data) {
      window.open(`/details?id=${data}&${this.category == 1 ? 'img' : 'video'}=true`, "_blank");
    },
    initData () {
      this.selectList = [];
      this.dataList = [];
      this.page = 0;
      this.load()
    },
    load () {
      if (this.loading && this.noMore) return;
      this.page++;
      this.loading = true;
      GlobalApi.getCollectionList({ id: this.dirId, page: this.page, page_size: this.page_size }).then(res => {
        this.loading = false
        if (res.status == 1) {
          this.dataList = this.dataList.concat(res.data.data);
          this.total_count = res.data.total;
          this.addSomeImages()
        }
      })
    },
    addSomeImages () {
      this.$nextTick(function () {
        $('#coryright-image-list').justifiedGallery({
          rowHeight: this.category == 1 ? 256 : 150,
          maxRowHeight: this.category == 1 ? 260 : 150,
          margins: 15,
          border: 0,
          waitThumbnailsLoad: false
        })
      })
    },
    // 新建文件夹
    addFolder () {
      this.collect.addVisible = true;
      this.collect.rename = false;
      this.collect.dirObj = {};
    },
    moveFolder (item) {
      if (item) {
        this.collect.moveIds = item.editor_id;
      } else {
        this.collect.moveIds = this.selectList.join(',');
      }
      this.collect.visible = true;
    },
    delFolder (item) {
      if (item) {
        this.toDel(item.editor_id)
      } else {
        this.$confirm('已选' + this.selectList.length + '个素材，是否确认删除？', '确认删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'banquan-message banquan-message-2',
          center: true
        }).then(() => {
          this.toDel(this.selectList.join(','));
        }).catch();
      }
    },
    toDel (id) {
      let url = this.category == 1 ? GlobalApi.delCollection : GlobalApi.delVideosCollection;
      url({ id: id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.page = 0;
          this.dataList = [];
          this.selectList = [];
          this.load()
        }
      });
    },
    //多选
    selectItem (id) {
      if (this.selectList.indexOf(id) >= 0) {
        this.selectList.remove(id)
      } else {
        this.selectList.push(id)
      }
    },
    //全选
    selectAll () {
      if (this.selectList.length == this.dataList.length) {
        this.selectList = []
      } else {
        this.selectList = []
        this.dataList.forEach(item => {
          this.selectList.push(item.editor_id)
        })
      }

    },
    //关闭移动弹窗
    closeCollect (refresh) {
      if (refresh) {
        this.page = 0;
        this.dataList = [];
        this.selectList = []
        this.load()
      }
      this.collect.visible = false;
    },
    windowKeyUpHandler () {
      let e = event || window.event || arguments.callee.caller.arguments[0];
      let keycode = e.keyCode || e.which;
      if (keycode == 27) {
        this.selectList = [];
      }
    },
    // 视频鼠标移入播放
    productEnter (data) {
      this.videoPl = true;
      let style = $("#img" + data.id).attr("style");

      setTimeout(() => {
        $("#video-box" + data.id).attr("style", style);
        if (document.getElementById("video" + data.id)) {
          let video = document.getElementById("video" + data.id);
          video.currentTime = 0;
          let playPromise = video.play();
          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                video.addEventListener("timeupdate", () => {
                  if (parseInt(video.currentTime) >= video.duration) {
                    video.currentTime = 0;
                    video.pause();
                  }
                });
              })
              .catch(() => { });
          }
        }
      }, 300)
    },
    // 视频鼠标移出暂停
    productLeave (data) {
      this.videoPl = false;
      if (document.getElementById("video" + data.id)) {
        let video = document.getElementById("video" + data.id);
        video.load();
      }
    },
  }
};
</script>
<style scoped lang="scss">
.collect-detail {
  .folder-title {
    padding: 0 66px 0 41px;
    margin-bottom: 25px;
    @include flex(center, space-between);

    &>p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 30px;

      span {
        margin-top: 2px;
        margin-right: 14px;
        float: left;
        width: 26px;
        height: 26px;
        line-height: 26px;
        background: #F6F7F9;
        border-radius: 4px;
        color: #999;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .caozuo {
      p {
        float: left;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 30px;

        span {
          float: left;
          margin: 7px 10px 0 0;
          width: 16px;
          height: 16px;
          @include flex(center, center);
          border-radius: 2px;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          border: 1px solid #A8A8A8;

          &.icon-success {
            background: #A8A8A8;
          }
        }
      }

      div {
        float: left;
        width: 66px;
        height: 30px;
        margin-left: 30px;
        background: #F5F8FD;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #7E87AF;
        line-height: 30px;
        cursor: pointer;

        img {
          width: 14px;
          height: 14px;
          float: left;
          margin: 8px 3px 0 11px;
        }

        &:last-of-type {
          margin-left: 12px;
        }
      }
    }
  }

  .data-scroll {
    padding: 0 66px 0 41px;
    // padding-bottom: 50px;
    overflow: auto;
    height: calc(100vh - 215px);

    &::-webkit-scrollbar {
      width: 0px;
      height: 0;
    }

    .data-list {
      overflow: auto;

      // padding: 0 40px;
      // flex-wrap: wrap;
      // @include flex(center,space-between);
      .image-card {
        .noclick {
          position: absolute;
          width: 100%;
          height: 100%;
          @include flex(center, center);
          background: rgba(102, 102, 102, 0.6);
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}

.video-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  border: none;

  .product-video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
